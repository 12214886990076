<template>
  <div class="container-component">
    <div class="row mb-4">
      <div class="col">
        <div
            class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h5 class="mb-0 font-size-18">Admins</h5>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="mb-5">
                  <h6 class="card-title">
                    Admin List
                    <span
                        class="text-muted fw-normal ms-2"
                        v-if="employees?.length > 0"
                    >({{ employees?.length }})</span
                    >
                  </h6>
                </div>

                <div class="col-12 d-flex mb-4">
                  <button
                      class="btn btn-sm btn-success"
                      @click="openAdminModal"
                  >
                    <font-awesome-icon icon="fa-solid fa-plus"/>
                    <span class="ml-2">New admin</span>
                  </button>
                  <!-- <button
                    class="btn btn-sm btn-grey ml-auto"
                    :disabled="selectedObjects.length === 0"
                    @click="deactivateEmployee"
                  >
                    <font-awesome-icon icon="fa-solid fa-ban" />
                    <span class="ml-2">Deactivate employee</span>
                  </button> -->
                  <button
                      class="btn btn-sm btn-danger-light ml-3"
                      :disabled="selectedObjects.length === 0"
                      @click="deleteEmployee"
                  >
                    <font-awesome-icon icon="fa-solid fa-trash"/>
                    <span class="ml-2">Delete admin</span>
                  </button>
                </div>

                <div class="col-12">
                  <div class="table-responsive">
                    <div class="table align-middle">
                      <b-table
                          class="employeesTable"
                          ref="selectableTable"
                          :items="employees"
                          :fields="fields"
                          responsive="sm"
                          selectable
                          select-mode="range"
                          tbody-tr-class="item"
                          @row-dblclicked="oeffneEmployee"
                      >
                        <template v-slot:head(selected)="header">
                          <b-form-checkbox
                              ref="headerCheckbox"
                              v-model="header.selected"
                              @input="selectAllRows(header)"
                          ></b-form-checkbox>
                        </template>

                        <template v-slot:cell(selected)="row">
                          <b-form-checkbox
                              v-model="row.item.selected"
                              @input="onRowSelected(row)"
                          ></b-form-checkbox>
                        </template>

                        <template slot="cell(status)" slot-scope="{ value }">
                            <span
                                v-if="value != 'Active'"
                                class="text-danger-light"
                            >{{ value }}</span
                            >
                          <span v-else class="text-success">{{ value }}</span>
                        </template>

                        <template v-slot:cell(edit)="row">
                          <div
                              class="clickable"
                              @click="setEditAdmin(row.item)"
                          >
                            <font-awesome-icon icon="fa-solid fa-pencil"/>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <admin-modal
        id="admin-modal"
        @addAdmin="addAdmin"
        :editAdmin="editAdmin"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import store from "@/store";

import Table from "@/mixins/Table";

import AdminModal from "@/components/Modals/AdminModal";

export default {
  name: "Admins",
  components: {
    AdminModal,
  },
  mixins: [Table],
  props: {},
  data() {
    return {
      editAdmin: null,
      employees: [],
      fields: [
        {key: "selected", label: ""},
        {
          key: "company.name",
          label: "Company",
          formatter: (item) => item == null ? '-' : item
        },
        {key: "firstname", label: "First name"},
        {key: "lastname", label: "Last name"},
        {key: "phone", label: "Phone"},
        {key: "email", label: "Email"},
        {key: "role.name", label: "Role"},
        {key: "position", label: "Position"},
        {key: "checksum", label: "Qr Codes"},
        {key: "edit", label: "Edit"},
      ],
      user: null,
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    this.getEmployees();
    if (!store.state.companySelectionList || store.state.companySelectionList.length === 0) {
      store.dispatch('selection/fetchSelectionList');
    }
  },
  methods: {
    async getEmployees() {
      await Api.get("user/").then((response) => {
        this.user = response.data;
      });

      Api.get("employee/", {
        params: {admins: "true"},
      }).then((response) => {
        this.employees = response?.data?.content;
      });
    },
    openAdminModal() {
      this.editAdmin = null;
      this.$nextTick(() => {
        this.$bus.$emit("open-modal", "admin-modal");
      });
    },
    setEditAdmin(employee) {
      this.editAdmin = employee;
      this.$bus.$emit("open-modal", "admin-modal");
    },
    addAdmin(employee) {
      if (employee) {
        if (employee.id) {
          //   const compIndex = this.employees.findIndex(
          //     (existingEmployee) => existingEmployee.id === employee.id
          //   );

          //   if (compIndex !== -1) {
          //     this.employees[compIndex] = employee;
          //   }

          //company aus user nehmen
          Api.put("/employee/" + employee.id, employee)
              .then(() => {
                this.$notify({
                  type: "success",
                  title: "Action Successful",
                  text: "Admin successfully edited.",
                  duration: 8000,
                });
                this.getEmployees();
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
                //this.resetLoading(this)
                this.getEmployees();

                this.$nextTick(() => {
                  this.$refs.selectableTable.refresh();
                });
              });
        } else {
          Api.post("/employee", employee)
              .then(() => {
                this.$notify({
                  type: "success",
                  title: "Action Successful",
                  text: "Admin successfully created and registration link sent.",
                  duration: 8000,
                });
                this.getEmployees();
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
                //this.resetLoading(this)
                this.getEmployees();

                this.$nextTick(() => {
                  this.$refs.selectableTable.refresh();
                });
              });
          //   employee.id = uuidv4();
          //   this.employees.push(employee);
        }
      }
    },
    oeffneEmployee(employee) {
      this.$router.push({
        name: "employee",
        params: {id: employee.id},
      });
    },
    deactivateEmployee() {
      this.selectedObjects.forEach((employee) => {
        employee.status = "Inactive";
      });
    },
    deleteEmployee() {
      Api.delete("employee/", {data: this.selectedIds})
          .then((response) => {
            this.selectedObjects = [];
            this.$notify({
              type: "success",
              title: "Action Successful",
              text: "Admin successfully deleted.",
              duration: 8000,
            });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            //this.resetLoading(this)
            this.getEmployees();

            this.$nextTick(() => {
              this.$refs.selectableTable.refresh();
            });
          });
    },
  },
};
</script>

<style lang="scss"></style>
  