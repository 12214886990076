<template>
    <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-white">
          <div class="modal-header bg-light-grey text-bold text-dark-blue p-3">
            <span v-if="!editAdmin">Add admin</span>
            <span v-else>Edit admin</span>
            <modal-close-button @confirmed="closeModal" />
          </div>
          <div class="modal-body">
            <div class="container px-3 pb-3">
              <!-- <div class="row mb-2 ml-0">
                <div class="col-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="statusCheckbox"
                      :checked="admin.status === 'Active'"
                      @change="updateAdminStatus"
                    />
                    <label class="form-check-label" for="statusCheckbox">
                      {{ admin.status }}
                    </label>
                  </div>
                </div>
              </div> -->

              <div class="row mb-2 ml-0">
                <div class="col-6">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Admin first name"
                      v-model="admin.firstname"
                    />
                    <label>Admin first name*</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Admin last name"
                      v-model="admin.lastname"
                    />
                    <label>Admin last name*</label>
                  </div>
                </div>
              </div>
  
              <div class="row mb-2 ml-0">
                <div class="col-6">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Admin phone number"
                      v-model="admin.phone"
                    />
                    <label>Admin phone number</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Admin email address"
                      v-model="admin.email"
                    />
                    <label>Admin email address*</label>
                  </div>
                </div>
              </div>
  
              <!-- <div class="row w-110-p">
                <div class="col-12">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Admin address"
                      v-model="admin.address"
                    />
                    <label>Admin address</label>
                  </div>
                </div>
              </div> -->
  
              <div class="row mb-2 ml-0">
                <div class="col-6">
                  <div class="form-group">
                    <v-select
                      class="w-100-p"
                      v-model="admin.role"
                      :options="roles"
                      label="name"
                      placeholder="Role"
                      :searchable="false"
                    ></v-select>
                    <label>Admin role*</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Admin position"
                      v-model="admin.position"
                    />
                    <label>Admin position</label>
                  </div>
                </div>
              </div>
              <div class="row ml-0">
                <div class="col-6">
                  <div class="form-group">
                    <v-select
                        class="w-100-p"
                        v-model="admin.company.id"
                        :options="store.getters['selection/getCompanySelectionList']"
                        :getOptionLabel="(item) => item.name"
                        placeholder="Select a Company"
                        :reduce="(item) => item.id"
                    > <span slot="no-options">No company found.</span></v-select>
                    <label>Admin company</label>
                  </div>
                </div>
              </div>
              <div class="row mb-2 ml-0">
                <div class="col-6 fs-10">*Mandatory</div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row w-100">
              <div class="col-12 d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-sm btn-grey mr-3"
                  data-dismiss="modal"
                  @click="closeModal"
                >
                  <span>Cancel</span>
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-success"
                  @click="saveAdmin"
                  :disabled="!formIsValid"
                >
                  <span v-if="!editAdmin">Send registration link</span>
                  <span v-else>Edit Admin</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
      
      <script>
  import { modal } from "../../mixins/Modal";
  import ModalCloseButton from "@/components/global/ModalCloseButton";
  import store from "@/store";

  export default {
    name: "AdminModal",
    components: {
      ModalCloseButton,
    },
    props: {
      id: {
        type: String,
        default: "admin-modal",
      },
      editAdmin: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        admin: {
          id: null,
          firstname: null,
          lastname: null,
          company: {
            id: null,
            name: null,
            address: null,
            phone: null,
            email: null,
            website: null,
          },
          role: { name: null, description: null },
          position: null,
          email: null,
          phone: null,
          status: "Active",
        },
        defaultAdmin: {
          id: null,
          firstname: null,
          lastname: null,
          company: {
            id: null,
            name: null,
            address: null,
            phone: null,
            email: null,
            website: null,
          },
          role: { name: null, description: null },
          position: null,
          email: null,
          phone: null,
          status: "Active",
        },
        roles: [
          {
            id: "18EEC46A8DB",
            name: "Country Admin",
            description: "",
            appkey: "countryadmin",
          },
        ],
      };
    },
    computed: {
      store() {
        return store
      },
      formIsValid() {
        if (
          this.admin.firstname &&
          this.admin.lastname &&
          this.admin.email &&
          this.admin.role?.id
        )
          return true;
        return false;
      },
    },
    mixins: [modal],
    mounted() {
      this.$bus.$on("open-modal", (id) => {
        if (id === "admin-modal") {
          this.$nextTick(() => {
            if (this.editAdmin) {
              this.admin = JSON.parse(JSON.stringify(this.editAdmin));
            } else {
              this.admin = JSON.parse(JSON.stringify(this.defaultAdmin));
            }
          });
        }
      });
    },
    methods: {
      saveAdmin() {
        this.$emit("addAdmin", this.admin);
        this.closeModal();
      },
      updateAdminStatus() {
        if (this.admin.status === "Active") {
          this.admin.status = "Inactive";
        } else {
          this.admin.status = "Active";
        }
      },
    },
  };
  </script>
